import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DefaultImage } from "~/old-app/constants";

const CardBrandGrid = ({ item, imgW = "full", imgH = "h-40" }) => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const goToBrand = () => {
    navigate(`/${language}/brand/${item?.slug}`);
  };
  return (
    <div className="w-full cursor-pointer" onClick={goToBrand}>
      <div className="relative flex flex-col min-w-0 break-words w-full">
        <div className={`border border-gray-80 rounded-lg w-32  bg-white`}>
          <img
            alt="..."
            src={item?.image || DefaultImage}
            className="w-32 h-32 object-cover rounded-lg align-middle"
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = DefaultImage;
            }}
          />
        </div>

        <blockquote className="relative py-4 pb-0">
          <h3 className="text-sm font-medium  text-secondary-950 text-center leading-3">
            {item?.name?.substring(0, 50)} {item?.name?.length > 50 && ".."}
          </h3>
        </blockquote>
      </div>
    </div>
  );
};

export default CardBrandGrid;
